import Vue from 'vue'
import VueI18n from 'vue-i18n';

import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueI18n);

const messages = {
    'en': {
      welcomeMsg: "Concept, development and implementation. I am supporting you in all phases of your software project, alone or as part of a team.",
      intro: "My name is Sascha Geringer. I'm a tech enthusiast, programmer, freelancer",
      motive: "A successful project starts from the customer and works backwards to drive decisions in an ambiguous environment. In this mindset I'm offering effective consulting and implementation of software projects to assist you in reaching your goals. My main experience and skills are:",
      university: "M. Sc. Robotics (TU Munich)",
      industry: "5 Years of Industry Experience",
      algo: "Algorithmus Design and Implementation ",
      gamedev: "Game Development / Unity Development",
      projectsHead: "Click here",
      projectsTail: "to find a selection of previous projects.",
      how: "How it works",
      step1Head: "Send me a mail to ",
      step1Tail: "to get a conversation started. Already have a concrete project? You are welcome to outline your planned project or ideas in a few words.",
      step2: "Let's chat about your immediate goals and long-term vision. I will help you to define goals and break them down into manageble steps.",
      step3: "You sit back and I do the heavy lifting. In a transparent and open process we keep an eye on the progress, discuss intermediate results and re-iterate if necessary to steer the projects towards the goal!",
      ger: "Germany",
      imp: "Imprint",
    },
    'de': {
      welcomeMsg: "Ob alleine oder im Team, ich begleite Sie mit Rat und Tat bei der Konzeptionierung, Entwicklung und Umsetzung von Software-Projekten.",
      intro: "Mein Name ist Sascha Geringer. Ich bin Tech-Enthusiast, Programmierer, Freelancer.",
      motive: "Am Anfang stehen die Vision und Bedürfnisse des Kunden, jede Entscheidung während eines Projektes leitet sich daraus ab. In diesem Mindset unterstütze ich Sie als erfahrener Software Engineer bei der Verwirklichung Ihrer Ziele mit der Planung, Beratung und Implementierung. Meine Expertise umfasst:",
      university: "M. Sc. Robotics (TU München)",
      industry: "5 Jahre Industrieerfahrung",
      algo: "Algorithmus Design und Implementierung",
      gamedev: "Game Development / Unity Entwicklung",
      projectsHead: "Hier",
      projectsTail: "finden Sie eine Auswahl vergangener Projekte.",
      how: "So einfach geht's",
      step1Head: "Schreiben Sie ganz einfach an",
      step1Tail: "für einen ersten unverbindlichen Kontakt. Schon ein konkretes Projekt? Gerne können Sie gleich in zwei, drei Sätzen Ihr geplantes Projekt oder Anliegen skizzieren.",
      step2: "Gemeinsam halten wir Ihre unmittelbaren Anforderungen und langfristige Vision fest. Ich helfe dabei, das Ziel zu definieren sowie Etappen festzulegen.",
      step3: "Ich setze meine Zahnräder in Bewegung und kümmere mich um den Rest. Transparenz und damit ein enger Austausch ist mir dabei wichtig. Zusammen behalten wir den Fortschritt stets im Auge, tauschen uns aus und bringen das Projekt zum Erfolg!",
      ger: "Deutschland",
      imp: "Impressum",
    }
};

const i18n = new VueI18n({
    locale: navigator.language.startsWith('de') ? 'de' : 'en',
    messages, // set locale messages
});

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
