<template>
  <div id="app" >
    <div class="heroBackground">
    <div class="info1Container" style="height: 20vh">
      <div class="flexRow">
        <span class="logoLink">
          A growing food business based in Germany.
        </span>
      </div>
    </div>
  </div>
    <div class="info1Container">

      <div class="flexRow">
      <span class="info1">Lead by:</span>
      <span class="info1">Sascha Geringer</span>
       </div>
      <div class="flexRow">
      <span class="info1">Location:</span>
      <span class="info1">Sinzing, Germany</span>
       </div>
      <div class="flexRow">
      <span class="info1">Email:</span>
      <span class="info1">hello@geringer.dev</span>
       </div>
      <div class="flexRow">
        <span class="info1">Sales Tax No.:</span>
        <span class="info1">DE300012217</span>
      </div>
    </div>
    </div>
</template>

<script>

export default {
  name: "App",
  components: {
  }
};
</script>

<style>
.imp {
  grid-column: 2 / span 2;
  font-size: 12px;
  text-align: center;
  margin-top: 40px;
}

.step1 {
  text-decoration: none;
  font-weight: 700;
  color: #23272a;
}

:root {
  --page-max-width: 1260px;
  --num-grid-columns: 4;
  --page-gutter: 24px;
  --section-spacing: 56px;
  --brand-color: #6a19ff;
  --brand-font: "Roboto Condensed", sans-serif;
  --brand-font-light: "Roboto", sans-serif;
}

* {
  font-family: var(--brand-font);
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeLegibility;
}

.mesh {
  background-color: white;
  position: relative;
  width: 100%;
  padding-top: 30%;
  overflow: hidden;
}

.mesh-canvas {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.avatar {
  grid-column: 2 / span 2;
  max-width: 256px;
  display: flex;
  align-items: center;
  justify-self: center;
}

.avatar-img {
  border-radius: 50%;
  width: 100%;
  /* border-width: 39px; */
  display: block;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
}

.info1Container {
  margin: 40px;

}

.info1 {
font-family: var(--brand-font);
font-weight: 700;
font-size: 40px;
color: linear;
margin-bottom: -10px;
text-align: left;
text-decoration: none;
padding-left: 10px;

}

.checks {
  grid-column: span var(--num-grid-columns);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 40px;
  background: white;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 10px;
  padding-right: 20px;
  font-size: 18px;
}

.check {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 20px;
  align-items: center;
}

.checkmark {
  grid-column: 1 / span 1;
  padding-right: 20px;
  justify-self: flex-end;
}

.checktext {
  grid-column: 2 / span 2;
}

.emailContainer {
  grid-column: span var(--num-grid-columns);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.talk {
  font-family: var(--brand-font);
  font-weight: 700;
  font-size: 40px;
  color: linear;
  margin-bottom: -10px;
  text-align: left;
  animation: pulse 5s infinite;
  text-decoration: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    color: var(--brand-color);
  }
  50% {
    transform: scale(1.05);
    color: rgb(0, 164, 189);
  }
  100% {
    transform: scale(1);
    color: var(--brand-color);
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
button,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}

.header {
  z-index: 9998;
}

.row1 {
  width: 100%;
  max-width: 1260px;
  max-width: var(--page-max-width);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(var(--num-grid-columns), 1fr);
  gap: 0 20px;
  padding: 0 24px;
  padding: 0 var(--page-gutter);
}

.wrapper {
  grid-column: span var(--num-grid-columns);
}

.nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 90vh;
}

.logoLink {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: var(--brand-font);
  font-size: 40px;
  font-weight: 700;
  color: white;
  text-decoration: none;
}

.heroBackground {
  background: linear-gradient(45deg, var(--brand-color), #00a4bd);
  position: relative;
  overflow: hidden;
}

.grid1 {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.callToActionContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

.ctaButton {
  text-decoration: none;
  margin-top: 24px;
  border-radius: 28px;
  font-size: 20px;
  padding: 16px 32px;
  background-color: #fff;
  color: #23272a;
  line-height: 24px;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, color, box-shadow;
  transition-property: background-color, color, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  font-family: var(--brand-font);
}

.ctaButton:hover {
  -webkit-box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
  box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
}

.icon {
  margin-right: 8px;
}

.icon:hover {
  color: var(--brand-color);
}

section {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  z-index: 1;
}

.heroBody {
  grid-column: span 4;
}

.heroText {
  color: #ffffff;
}

.cards {
  display: grid;
  flex-direction: row;
  grid-column: span var(--num-grid-columns);
  grid-template-columns: repeat(1, 1fr);
  gap: 0 20px;
  justify-content: space-between;
  margin-top: 120px;
  margin-bottom: 280px;
}

.steps {
  display: grid;
  flex-direction: row;
  grid-column: span var(--num-grid-columns);
  grid-template-columns: repeat(1, 1fr);
  gap: 0 20px;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}

.stepHeading {
  grid-column: span var(--num-grid-columns);
  text-align: center;
  font-family: var(--brand-font);
  font-size: 40px;
  font-weight: 700;
  color: #747373;
}

h1 {
  font-family: var(--brand-font);
  font-weight: 800;
  font-size: clamp(24px, 5vw, 44px);
  line-height: 95%;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}

.heroSubtitle {
  margin-top: 24px;
  font-size: 20px;
  font-size: clamp(16px, 2vw, 20px);
  line-height: 1.625;
  font-family: var(--brand-font);
}

.heroImageContainer {
  background: transparent;
  position: relative;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.foregroundLeft {
  display: block;
  width: 100%;
  max-width: 256px;
  position: relative;
  z-index: 1;
  margin-left: 0px;
}

.plane {
  grid-column: span var(--num-grid-columns);
  width: 100%;
  max-width: 512px;
}

.sectionContainer {
  padding: var(--page-gutter);
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
  display: grid;
  grid-template-columns: repeat(var(--num-grid-columns), 1fr);
  width: 100%;
  max-width: var(--page-max-width);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  gap: 0 20px;
}

.featureImage {
  max-height: 512px;
  justify-self: center;
}

.imageLeft {
  overflow: hidden;
  grid-column: span var(--num-grid-columns);
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 24px;
}

.imageRight {
  grid-column: span var(--num-grid-columns);
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 24px;
}

.imageCenter {
  grid-column: 2 / span 2;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 24px;
  max-width: 512px;
  justify-self: center;
}

.descriptionLeft {
  margin-top: 20px;
  color: #23272a;
  grid-column: span var(--num-grid-columns);
}

.descriptionCenter {
  color: #23272a;
  grid-column: span var(--num-grid-columns);
  margin-top: 20px;
}

.descriptionHeading {
  font-weight: 700;
  line-height: 120%;
  font-size: clamp(20px, 4vw, 40px);
  font-family: var(--brand-font);
}

.descriptionText {
  font-family: var(--brand-font-light);
  margin-top: 24px;
  font-size: clamp(16px, 2vw, 20px);
  line-height: 1.625;
}

.footer {
  background-color: #23272a;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.info {
  grid-column: span 4;
  justify-self: left;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.contactContainer {
  grid-column: span var(--num-grid-columns);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-column: span var(--num-grid-columns);
}

.email {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: "Roboto Condensed";
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
}

.emailText {
  color: #23272a;
  text-decoration: none;
}

.footerText {
  color: white;
  text-decoration: none;
  font-family: var(--brand-font);
  font-weight: 300;
  font-size: 20px;
}

.finalCta {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--section-spacing);
}

.ctaTitle {
  margin-top: 30px;
  z-index: 1;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  font-family: var(--brand-font);
}

@media screen and (min-width: 768px) {
  :root {
    --num-grid-columns: 8;
    --page-gutter: 40px;
    --section-spacing: 80px;
  }

  .imp {
    grid-column: 7 / span 2;
    text-align: left;
    margin-top: 0px;
  }

  .cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .steps {
    grid-template-columns: repeat(3, 1fr);
  }

  .heroBackground {
    padding-bottom: 56px;
    padding-bottom: var(--section-spacing);
  }

  .heroBody {
    grid-column: span 5;
  }

  .heroText {
    margin-bottom: 8px;
  }

  h1 {
    font-size: 56px;
  }

  .heroSubtitle {
    margin-top: 40px;
    font-size: clamp(16px, 2vw, 20px);
    line-height: 1.625;
  }

  .heroImageContainer {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .foregroundLeft {
    display: block;
    bottom: 0;
    left: 50%;
    margin-left: 149px;
    position: absolute;
  }

  .imageLeft {
    grid-column: span var(--num-grid-columns);
    margin-top: auto;
    margin-bottom: auto;
  }

  .imageRight {
    grid-column: span 4;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: auto;
    margin-bottom: auto;
  }

  .imageCenter {
    grid-column: 3 / span 4;
  }

  .descriptionRight {
    order: 1;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0;
    grid-column: span 4;
  }

  .descriptionLeft {
    margin-top: 0;
    grid-column: span 4;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .descriptionCenter {
    text-align: center;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-column: 5 / span 3;
    margin-top: 0px;
  }

  .checks {
    padding-top: 20px;
    margin-top: 80px;
    grid-column: 3 / span 4;
  }

  .descriptionHeading {
    font-size: 48px;
  }

  .avatar {
    max-width: 196px;
    grid-column: 2 / span 3;
    align-self: center;
    justify-self: center;
  }
}

.projects {
 margin-top: 40px;
 align-self: center;
 text-align: center;
}

@media screen and (min-width: 1024px) {
  :root {
    --num-grid-columns: 12;
    --section-spacing: 120px;
  }

  .imp {
    grid-column: 11 / span 2;
  }

  .heroBackground {
    padding-bottom: 0;
  }

  .heroBody {
    grid-column: 3 / span 8;
    text-align: center;
  }

  .heroSubtitle {
    margin-top: 40px;
  }

  .foregroundLeft {
    display: block;
    bottom: 0;
    left: 50%;
    margin-left: 369px;
  }

  .imageLeft {
    grid-column: span var(--num-grid-columns);
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .imageRight {
    grid-column: 7 / span 6;
  }

  .imageCenter {
    grid-column: 5 / span 4;
  }

  .avatar {
    grid-column: 2 / span 2;
  }

  .descriptionCenter {
    grid-column: 5 / span 7;
    text-align: left;
  }

  .checks {
    grid-column: 4 / span 6;
  }
}
</style>
